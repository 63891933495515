// src/hooks/useAuthorization.ts
import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import locations, { LocationConfig } from "@/data/locations";

// authorized user manager groupid
const groupId = "8cba404f-a3d3-4676-9923-1484786936ce";

// Canadian IO groups
const canGroups = [
  "1ad0bb0b-2534-48c2-b61e-354b0bf28186",
  "4e6f1eed-1a6a-4f82-b284-f9147680d5a3",
  "ead44555-db7a-4353-8955-fbeb09d44e60",
  "f0537711-7eb2-4abc-a26a-74df4ea6dcce",
  "23524a13-b941-41be-9b07-03d67f3aae70",
  "4e327b4f-441b-41e4-bc0e-d927507f9ad3",
  "72997dbc-781f-497d-8122-0143279e3ea7",
  "8ffa9a95-2801-4d49-b638-cea37851513b",
  "b76f6df0-6b73-4e51-b9e9-67be98dfe524",
  "e65624e8-3f85-4cdd-93d7-810dc80d0103",
  "900fdae6-1579-4ccc-b835-355f6cc5dc7b",
  "7da71270-2db7-46b5-86ad-c4e4973bfb54",
  "9ff930a2-adcb-4346-93aa-fb9cbd88890c",
];

function useAuthorization() {
  const { data: session, status } = useSession();
  const [authState, setAuthState] = useState({
    isAuthorized: false,
    isCanadian: false,
    isLoaded: false,
  });
  const [location, setLocation] = useState<LocationConfig | null>(null);

  useEffect(() => {
    if (status === "authenticated") {
      const authorized = session?.user.groups.includes(groupId);
      const canadian = session?.user.groups.some((group) =>
        canGroups.includes(group)
      );
      setLocation(getLocationGroup(session?.user.groups));
      setAuthState({
        isAuthorized: authorized ?? false,
        isCanadian: canadian ?? false,
        isLoaded: true,
      });
    } else if (status === "unauthenticated") {
      setAuthState({ isAuthorized: false, isCanadian: false, isLoaded: true });
    }
  }, [session, status]);

  console.log(
    "Logged in user:",
    "auth: ",
    authState,
    session,
    status,
    "location: ",
    location
  );

  return { ...authState, session, status, location };
}

export default useAuthorization;

function getLocationGroup(groups: string[]): LocationConfig | null {
  // see if any groups match one of the locations.id. return the location
  const l = locations.find((loc) => groups.includes(loc.id));
  // l && console.log("Location identified: ", l);
  return l ? l : null;
}
